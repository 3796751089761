import React from "react";
import File from "./file";

const FileContainer = (file = {}, path, name, className) => {
	const { node: { prettySize: size, extension } = {} } = file;
	return (
		<File
			name={name}
			type={extension}
			size={size}
			link={path}
			className={className}
		/>
	);
};

FileContainer.propTypes = {};

export default ({ path, name, className, files }) => {
	const arrayPath = path.split("/");
	const nameWithExt = arrayPath[arrayPath.length - 1];
	return (
		files &&
		Array.isArray(files) &&
		files.length &&
		FileContainer(
			files.find(file => ~file.node.relativePath.indexOf(nameWithExt)),
			path,
			name,
			className
		)
	);
};
