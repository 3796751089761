import "./for-legal-entities.scss";
import React from "react";
import { graphql, StaticQuery } from "gatsby";
import FileContainer from "@components/file/file.container";

const instructions = [
	{
		name: "Пополнение ЭПБ Работников на 2020 год",
		path: "/assets/to-legal-person/Пополнение ЭПБ Работников на 2020 год.doc"
	},
	{
		name: "Договор для пополнения ЭПБ для Юридических лиц",
		path:
			"/assets/to-legal-person/Договор купли-продажи транспортных карт 2020.doc"
	}
];

const ForLegalEntities = ({ data }) => {
	const { files: { edges: files } = {} } = data;
	let i = 0;
	return (
		<section className="full-width for-legal-entities">
			<div className="grid-container">
				<div className="row">
					<div className="col-xs-12 col-md-offset-2 col-md-8 col-sm-offset-1 col-sm-10">
						<h2 className="for-legal-entities__header">Юридическим лицам</h2>
					</div>
				</div>
			</div>

			<div className="grid-container">
				<div className="row">
					{instructions.map(instruction => {
						i += 1;
						return (
							<div
								key={i}
								className={`col-xs-12 ${
									i % 2
										? "col-md-offset-2 col-md-4 col-sm-offset-1 col-sm-5"
										: "col-md-4 col-sm-5"
								}`}
							>
								<FileContainer
									className="for-legal-entities__file"
									name={instruction.name}
									path={instruction.path}
									files={files}
								/>
							</div>
						);
					})}
				</div>
			</div>
		</section>
	);
};

export default props => (
	<StaticQuery
		query={graphql`
			query {
				files: allFile(
					filter: {
						extension: { regex: "/pdf|docx|doc|xlsx|xls/" }
						relativePath: { regex: "/to-legal-person/" }
					}
				) {
					edges {
						node {
							name
							relativePath
							prettySize
							root
							sourceInstanceName
							base
							birthTime
							ext
							extension
						}
					}
				}
			}
		`}
		render={data => <ForLegalEntities data={data} {...props} />}
	/>
);
